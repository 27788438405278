import React from 'react'
import { ExploreProgram } from '@citruscamps/citrus-client'

export const Footer = ({
  program,
  theme,
}: {
  program?: ExploreProgram
  theme?: 'dark' | 'light'
}) => {
  return (
    <footer
      className={['text-center pt-5 pb-3 py-md-3', theme === 'dark' ? 'text-white' : 'text-dark']
        .filter(Boolean)
        .join(' ')
        .trim()}
    >
      {/* TODO: Remove if not needed */}
      {/* {!!program?.preferences?.treaty && (
        <div className="mb-2">
          We would like to acknowledge that {program?.name} operates on{' '}
          {program?.preferences?.treaty.charAt(0).toUpperCase() +
            program?.preferences?.treaty.slice(1).split('_').join(' ')}{' '}
          territory.
        </div>
      )} */}
      <div className="h-100 my-auto">
        <a href="https://www.citruscamps.com">
          <img
            src={theme === 'dark' ? '/imgs/logo-white.png' : '/imgs/logo-dark.png'}
            className="d-block align-baseline d-md-inline my-1 my-md-0 mx-auto mx-md-2"
            alt="Citrus"
            style={{ height: '1rem' }}
          />
        </a>

        <div className="d-block d-md-inline mx-2">Software to build your sports business</div>
        <div className="d-block d-md-inline">
          <a
            href="https://www.citruscamps.com/privacy"
            className={['mx-2', theme === 'dark' ? 'text-white' : 'text-dark'].join(' ').trim()}
          >
            Privacy
          </a>
          <a
            href="https://www.citruscamps.com/terms"
            className={['mx-2', theme === 'dark' ? 'text-white' : 'text-dark'].join(' ').trim()}
          >
            Terms
          </a>
        </div>
      </div>
    </footer>
  )
}
